import { FirebaseService } from 'src/app/services/firebase.service';
import { TemplateViewComponent } from 'src/app/components/template/template-view/template-view.component';
import { Component, ViewChild, ViewContainerRef, ComponentFactory, ComponentFactoryResolver, AfterViewInit, ElementRef } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { CV, Template } from 'src/app/app.component';
import domtoimage from 'dom-to-image';
import { transService } from 'src/app/services/translation.service';

@Component({
  selector: 'app-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})

export class ModalTemplateComponent implements AfterViewInit {
  // ------------- ModalTemplateComponent ------------- //
  constructor(private navParams: NavParams,
    private modalCtrl: ModalController,
    private resolver: ComponentFactoryResolver,
    private firebaseService: FirebaseService,
  ) {

    //this.dictionary = transService.getDictionary();
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.template = navParams.get('data');
    }
  }

  // ------------- Attributes ------------- //
  language = 'en';
  lng = 1;
  template: Template = {
    name: '',
    img: '',
    html: '',
    banner: false,
    bannerColor: '#3e6158',
    bannerPosition: 'left',
    fixedBannerColor: null,
    font: {
      family: 'Arial',
      h1: 36,
      h2: 16,
      h3: 14,
      t1: 12,
      t2: 10
    },
    textAlign: 'left',
    lineHeight: 1.6
  };
  cv: CV = {
    name: 'NOMBRE COMPLETO',
    city: 'Bogotá, Colombia',
    birthDate: undefined,
    email: 'correo@correo.com',
    tel: [3108097111],
    career: 'Ingeniero electrónico',
    aboutMe: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut odio nisi, lobortis quis gravida gravida, fringilla in nulla. Nulla condimentum odio et est convallis cursus. Duis et pharetra velit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut odio nisi, lobortis quis gravida gravida, fringilla in nulla. Nulla condimentum odio et est convallis cursus. Duis et pharetra velit.',
    workExperience: true,
    workExperiences: [{
      position: 'Desarrollador de software',
      company: 'Accenture',
      start: '2020-08-04' as any,
      end: undefined,
      bossName: '',
      bossTel: '',
      responsibilities: [],
      achievements: [],
      description: 'Fusce ultrices nunc quis consequat efficitur. In hac habitasse platea dictumst. Vestibulum felis nibh, bibendum sit amet rutrum nec, facilisis in lectus. In hac habitasse platea dictumst. Vestibulum tincidunt quis mauris quis egestas. Nulla eu neque magna. Aenean feugiat est orci, vel tincidunt quam tincidunt id. Praesent aliquet nisl sit amet leo aliquet tempor. Donec elementum porta nibh, non pretium augue ullamcorper et.',
      city: 'Bogotá, Colombia',
      actual: true,
    },
    {
      position: 'Desarrollador de software 3',
      company: 'Claro',
      start: '2019-03-01' as any,
      end: '2020-08-01' as any,
      bossName: '',
      bossTel: '',
      responsibilities: [],
      achievements: [],
      description: 'Vivamus consectetur, tellus vitae aliquam mattis, felis mauris finibus ipsum, at ornare quam neque ut lectus. Praesent consectetur efficitur lobortis. Cras vitae nibh velit. Donec at leo dignissim, aliquam nibh et, ultrices ante. Phasellus molestie dapibus massa vitae accumsan. Sed ut leo tortor. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Fusce consequat lectus quis ipsum commodo pulvinar. Ut rhoncus sem lorem, consectetur efficitur urna accumsan et.',
      city: 'Bogotá, Colombia',
      actual: false,
    }],
    socialMedia: [{ name: 'Facebook', url: 'facebook.com' }, { name: 'Twitter', url: 'twitter.com' }],
    languages: [{ name: 'Inglés', level: 'C1' }, { name: 'Francés', level: 'A1' },
    { name: 'Mandarin', level: 'B2' }, { name: 'Aleman', level: 'A1' }
    ],
    education: [{
      institution: 'Pontificia Universidad Javeriana', type: 'Universidad', career: 'Ingenieria electrónica',
      start: '2015-08-01' as any, end: undefined, city: 'Bogotá, Colombia', actual: true
    }],
    tools: [{ name: 'Excel', level: 'Medio', category: 'Avanzado' },
    { name: 'Python', level: 'Avanzado', category: 'Avanzado' }
    ],
    awards: ['Tesis laureada', 'Excelencia académica'],
    aspirations: ['Ser CEO de Google', 'Ser empresario'],
    references: [{name:"Andres Lopez",tel:6541254}, {name:"Jose Ors",tel:315854521}],
    interests: ['Programacion','Ingenieria'],
    update: false,
    english: false,
    hideReferences: false,
    language: this.language
  };
  htmlFile: File = undefined;
  cvHtml = '';
  editing = false;
  // ------------- Elements ------------- //
  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;
  @ViewChild('MainContainer') mainContainer: ElementRef;

  ngAfterViewInit(): void {
    if (this.editing) {
      this.setTemplate();
    }
  }

  async submit() {
    if (this.editing && this.htmlFile) {
      this.firebaseService.removeFile(this.template.html);
      this.firebaseService.removeFile(this.template.img);
    }
    // Upload files
    if (this.htmlFile) {
      await this.firebaseService.uploadFile('templates', this.htmlFile, this.template.name).then((url: string) => {
        this.template.html = url;
      });
      let img: string;
      // Generate img
      await domtoimage.toPng(this.mainContainer.nativeElement).then((dataUrl: string) => {
        img = dataUrl;
      }).catch((error) => { console.error('ERROR: ', error); });
      // Upload image
      await this.firebaseService.uploadImg('images', img, this.template.name).then((url: string) => {
        this.template.img = url;
      });
    }
    // Dismiss modal
    this.modalCtrl.dismiss({
      item: this.template
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  // ------------- CV functions ------------- //
  createTemplate() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(TemplateViewComponent);
    const componentRef = this.container.createComponent(factory);
    componentRef.instance.cv = this.cv;
    componentRef.instance.cvHtml = this.cvHtml;
    componentRef.instance.template = this.template;
    this.updateCV();
  }

  setFontSize(fontOffset: number) {
    this.template.font.h1 += fontOffset;
    this.template.font.h2 += fontOffset;
    this.template.font.h3 += fontOffset;
    this.template.font.t1 += fontOffset;
    this.template.font.t2 += fontOffset;
    this.updateCV();
  }

  setLineHeight(heightOffset: number) {
    this.template.lineHeight = this.round(this.template.lineHeight + heightOffset, 1);
    this.updateCV();
  }

  setBannerColor(color: string) {
    this.template.bannerColor = color;
  }

  updateCV() {
    // Update paginated view
    this.cv.update = true;
    setTimeout(x => { this.cv.update = false; }, 500);
  }

  // ------------- Others ------------- //
  setHtml(file: any) {
    (document.getElementById('htmlFile') as HTMLInputElement).value = file[0].name;
    this.htmlFile = file[0];
    this.readHtml();
  }

  readHtml() {
    const fileReader = new FileReader();
    fileReader.onloadend = (e) => {
      this.cvHtml = fileReader.result as string;
      this.createTemplate();
    };
    fileReader.readAsText(this.htmlFile);
  }

  async setTemplate() {
    await this.firebaseService.getFileContent(this.template.html).then((data: string) => {
      this.cvHtml = data;
    });
    (document.getElementById('htmlFile') as HTMLInputElement).value = 'template.html';
    this.createTemplate();
  }

  round(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }
}
