import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule, PopoverController } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

// Modals
import { ModalTelComponent } from './modals/modal-tel/modal-tel.component';
import { ModalSocialComponent } from './modals/modal-social/modal-social.component';
import { ModalExpComponent } from './modals/modal-exp/modal-exp.component';
import { ModalEducationComponent } from './modals/modal-education/modal-education.component';
import { ModalToolsComponent } from './modals/modal-tools/modal-tools.component';
import { ModalLanguagesComponent } from './modals/modal-languages/modal-languages.component';
import { ModalAwardsComponent } from './modals/modal-awards/modal-awards.component';
import { ModalAspirationsComponent } from './modals/modal-aspirations/modal-aspirations.component';
import { ModalReferencesComponent } from './modals/modal-references/modal-references.component';
import { ModalFacultyComponent } from './modals/modal-faculty/modal-faculty.component';
import { ModalTemplateComponent } from './modals/modal-faculty/modal-template/modal-template.component';

// Pops
import { PopTelComponent } from './modals/modal-tel/pop-tel/pop-tel.component';
import { PopSocialComponent } from './modals/modal-social/pop-social/pop-social.component';
import { PopToolsComponent } from './modals/modal-tools/pop-tools/pop-tools.component';
import { PopLanguagesComponent } from './modals/modal-languages/pop-languages/pop-languages.component';
import { PopAwardsComponent } from './modals/modal-awards/pop-awards/pop-awards.component';
import { PopAspirationsComponent } from './modals/modal-aspirations/pop-aspirations/pop-aspirations.component';

// Others
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ModalPrevisualizationComponent } from './modals/modal-previsualization/modal-previsualization.component';
import { ModalLoginComponent } from './modals/modal-login/modal-login.component';


@NgModule({
  declarations: [
    ModalTelComponent, PopTelComponent,
    ModalSocialComponent, PopSocialComponent,
    ModalExpComponent,
    ModalEducationComponent,
    ModalToolsComponent, PopToolsComponent,
    ModalLanguagesComponent, PopLanguagesComponent,
    ModalAwardsComponent, PopAwardsComponent,
    ModalAspirationsComponent, PopAspirationsComponent,
    ModalReferencesComponent,
    ModalFacultyComponent,
    ModalTemplateComponent,
    HeaderComponent,
    FooterComponent,
    ModalPrevisualizationComponent,
    ModalLoginComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    RouterModule,
    FormsModule,
    NgScrollbarModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent
  ]
})
export class ComponentsModule { }
