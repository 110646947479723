import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { SocialMedia } from 'src/app/app.component';

@Component({
  selector: 'app-pop-social',
  templateUrl: './pop-social.component.html',
  styleUrls: ['./pop-social.component.scss'],
})

export class PopSocialComponent {
  // ------------- Attributes ------------- //
  value: SocialMedia = {
    name : '',
    url : ''
  };
  editing: false;

  // ------------- PopSocialComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit(){
    this.popCtrl.dismiss({
        item: this.value
    });

  }

}

