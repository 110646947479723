import { Component, OnInit, ViewChild } from '@angular/core';
import { PopoverController, IonSelect, NavParams, ModalController } from '@ionic/angular';
import { Reference } from 'src/app/app.component';

@Component({
  selector: 'app-modal-references',
  templateUrl: './modal-references.component.html',
  styleUrls: ['./modal-references.component.scss'],
})

export class ModalReferencesComponent {

  // ------------- Attributes ------------- //
  value: Reference = {
    name: '',
    tel: {} as any
  };
  editing = false;
  today = new Date().toJSON().split('T')[0];

  // ------------- ModalReferenceComponent ------------- //
  constructor(private navParams: NavParams,
              private modalCtrl: ModalController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit() {
    this.modalCtrl.dismiss({
      item: this.value
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
