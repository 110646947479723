import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-aspirations',
  templateUrl: './pop-aspirations.component.html',
  styleUrls: ['./pop-aspirations.component.scss'],
})

export class PopAspirationsComponent {
  // ------------- Attributes ------------- //
  value: string;
  editing: false;

  // ------------- PopAspirationsComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit(){
    this.popCtrl.dismiss({
        item: this.value
    });
  }

  close(){
    this.popCtrl.dismiss();
  }

}

