import { Component, OnInit, ViewChild } from '@angular/core';
import { PopoverController, IonSelect, NavParams, ModalController } from '@ionic/angular';
import { Education } from 'src/app/app.component';

@Component({
  selector: 'app-modal-education',
  templateUrl: './modal-education.component.html',
  styleUrls: ['./modal-education.component.scss'],
})

export class ModalEducationComponent {

  // ------------- Attributes ------------- //
  education: Education = {
    institution: '',
    type: 'Colegio',
    career: '',
    start: undefined,
    end: undefined,
    city: '',
    actual: false
  };
  editing = false;
  today = new Date().toJSON().split('T')[0];

  // ------------- ModalEducationComponent ------------- //
  constructor(private navParams: NavParams,
              private modalCtrl: ModalController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.education = navParams.get('data');
    }
  }

  submit() {
    this.modalCtrl.dismiss({
      item: this.education
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }
}
