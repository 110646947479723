import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { SocialMedia } from 'src/app/app.component';
import { PopSocialComponent } from './pop-social/pop-social.component';

@Component({
  selector: 'app-modal-social',
  templateUrl: './modal-social.component.html',
  styleUrls: ['./modal-social.component.scss'],
})

export class ModalSocialComponent {
  // ------------- Attributes ------------- //
  socialMedia: SocialMedia[];

  // ------------- ModalSocialComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController,
              private modalCtrl: ModalController) {
    this.socialMedia = navParams.get('data');
  }

  // ------------- Functions ------------- //
  async add(){
    const popover = await this.popCtrl.create({
      component: PopSocialComponent,
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data) {
      this.socialMedia.push(data.item);
    }
  }

  async edit(itemIndex: number){
    const popover = await this.popCtrl.create({
      component: PopSocialComponent,
      componentProps: { editing: true, data: this.copy(this.socialMedia[itemIndex])}
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    this.socialMedia[itemIndex] = data.item;
  }

  remove(itemIndex: number){
    this.socialMedia.splice(itemIndex, 1);
  }

  close(){
    this.modalCtrl.dismiss();
  }

  // ------------- Others ------------- //
  copy(item: any) {
    return JSON.parse(JSON.stringify(item));
  }
}
