import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ModalLoginComponent } from '../components/modals/modal-login/modal-login.component';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AdminLoginGuard implements CanActivate {

  constructor(private _authService: AuthService,
    private _modalController: ModalController,
    private router: Router) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    const user = await this._authService.getUserStatus()
    const userData: any = (await this._authService.getUserData(user.uid))

    if (userData?.admin)
      return true;


    const modal = await this._modalController.create({
      component: ModalLoginComponent, mode: 'ios',
    });
    await modal.present();
    this.router.navigate(['home']);
    return false;


  }

}
