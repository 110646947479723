import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-modal-login',
  templateUrl: './modal-login.component.html',
  styleUrls: ['./modal-login.component.scss'],
})
export class ModalLoginComponent implements OnInit {

  valor = {

    correo: '',
    pass: ''

  }
  valorR = {
    nombre: '',
    correo: '',
    pass: ''
  }

  showPassword = false;
  tipo = "password"

  resgistrando = false;

  //DATA FOR LOGIN
  datos
  checkout: boolean;

  // @ContentChild(IonInput) input: IonInput;

  constructor(private fsService: AuthService,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private db: AngularFirestore,
    private router: Router) { }

  ngOnInit() {

  }

  cambio() {
    this.resgistrando = true;
    this.tipo = "password"
    this.showPassword = false
  }

  cambioI() {
    this.resgistrando = false;
    this.tipo = "password"
    this.showPassword = false
  }

  registrar() {
    if (!this.valorR.correo || !this.valorR.nombre || !this.valorR.pass) {
      // alert('Completa todos los campos.')
      this.presentToast('Completa todos los campos.')
    } else {
      let user = {
        nombre: this.valorR.nombre,
        correo: this.valorR.correo
      }
      this.fsService.registrarUsuario(this.valorR.correo, this.valorR.pass, user).then(res => {
        this.modalCtrl.dismiss()
        // alert('¡Bienvenido a Selectos ' + this.valorR.nombre +'!')
        this.presentToast('¡Bienvenido de nuevo a Selectos ' + this.datos.nombre + '!')
        this.router.navigate(['/perfil-usuario'])
      }).catch(err => {
        console.error(err)
        // alert('Ocurrió un error en la sesión.')
        this.presentToast('Usuario o contraseña invalida".')
      })
    }
    this.resgistrando = false;
  }


  iniciar() {
    if (!this.valor.correo || !this.valor.pass) {
      // alert('Completa todos los campos.')
      this.presentToast('Completa todos los campos.')
    } else {
      this.fsService.SignIn(this.valor.correo, this.valor.pass).then(res => {
        this.db.collection("Usuarios").doc(res.user.uid).get().subscribe(res => {
          this.datos = res.data()
          // if (res.data().admin) {
          //  this.router.navigate(['/intranet']);
          //  this.admin=true
          // }
          this.modalCtrl.dismiss()
          // alert('¡Bienvenido de nuevo a Selectos '+ this.datos.nombre +'!')
          this.presentToast('¡Bienvenido de nuevo a Selectos ' + this.datos.nombre + '!')
          this.router.navigate(['/perfil-usuario'])
        })
      }).catch((error) => {
        console.log(error)
        // alert("Usuario o contraseña invalida.");
        this.presentToast('Usuario o contraseña invalida".')
      })
    }
  }

  //Show
  toggleShow() {
    this.showPassword = !this.showPassword;
    this.tipo = this.showPassword ? 'text' : 'password';
  }

  //Toast mensaje
  async presentToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 2000
    });
    toast.present();
  }

  iniciarSesionConGoogle() {
    this.fsService.loginGoogle().then(res => {
      let response = res as any
      if (response.nuevoUser) {
        let user = {
          nombre: response.user.displayName,
          correo: response.user.email
        }
        this.db.collection("Usuarios").doc(response.user.uid).set(user)
        this.presentToast('Bienvenido ' + response.user.displayName);
      } else {
        this.presentToast('Bienvenido de nuevo ' + response.user.displayName);
      }
      this.modalCtrl.dismiss()
    }).catch(error => {
      switch (error.code) {
        case "auth/invalid-email":
          this.presentToast(
            "La dirección de correo electrónico tiene un formato incorrecto");
          break;
        case "auth/user-not-found":
          this.presentToast("No hay ningún registro de usuario que corresponda a este correo. Verifique el correo.");
          break;
        case "auth/wrong-password":
          this.presentToast("La contraseña no es válida");
          break;

        default:
          this.presentToast(error);
          break;
      }
    })
  }
}

