import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ScreenSizeService } from './services/screensize.service';
import { UserHelperService } from './services/user-helper/user-helper.service';
import { Storage } from '@ionic/storage-angular';

// ------------- Interfaces ------------- //

// Faculty
export interface Faculty {
  id: any;
  name: string;
  img: string;
  cv: Template[];
}

export interface Template {
  name: string;
  img: string;
  html: string;
  banner: boolean;
  bannerColor: string;
  bannerPosition: string;
  fixedBannerColor: string;
  font: Font;
  textAlign: string;
  lineHeight: number;
}

// Font
export interface Font {
  family: string;
  h1: number;
  h2: number;
  h3: number;
  t1: number;
  t2: number;
}

// CV
export interface CV {
  name: string;
  city: string;
  birthDate: Date;
  email: string;
  tel: number[];
  career: string;
  aboutMe: string;
  workExperience: boolean;
  workExperiences: WorkExperience[];
  socialMedia: SocialMedia[];
  languages: Language[];
  education: Education[];
  tools: Tool[];
  awards: string[];
  aspirations: string[];
  interests: string[];
  references: Reference[];
  // Others
  update: boolean;
  english: boolean;
  language: string;
  hideReferences: boolean;
}

// Work experience
export interface WorkExperience {
  position: string;
  description: string;
  company: string;
  city: string;
  actual: boolean;
  start: Date;
  end: Date;
  bossName: string;
  bossTel: string;
  responsibilities: string[];
  achievements: string[];
}

// Social Media
export interface SocialMedia {
  name: string;
  url: string;
}

// Language
export interface Language {
  name: string;
  level: string;
}

// Education
export interface Education {
  institution: string;
  type: string;
  career: string;
  start: Date;
  end: Date;
  city: string;
  actual: boolean;
}

// Tool
export interface Tool {
  name: string;
  level: string;
  category: string;
}

// Reference
export interface Reference {
  name: string;
  tel: number;
}

// ------------- App component ------------- //
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})



export class AppComponent {

  // ------------- Constructor ------------- //
  constructor(private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private screensizeService: ScreenSizeService,
    private helperService: UserHelperService,
    private storage: Storage) {
    this.initializeApp();
  }

  helper: any;
  cookiesTerms: boolean;

  initializeApp() {
    this.platform.ready().then(async () => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.screensizeService.onResize(this.platform.width());
      this.suscribeToHelper();
      this.storage.create();
      this.cookiesTerms = await this.storage.get('termsAccepted')
    });
  }

  setTermsAccepted(answer: boolean) {
    this.storage.set('termsAccepted', answer)
    this.cookiesTerms = answer;
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  suscribeToHelper() {
    this.helperService.getHelper().subscribe((res) => {
      this.helper = res;
    })
  }

}
