import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ScreenSizeService {

  private isDesktop = new BehaviorSubject(false);
  private screenSize = new BehaviorSubject(500);

  constructor() { }

  onResize(size: number) {
    this.screenSize.next(size);
    if (size < 568)
      this.isDesktop.next(false);
    else
      this.isDesktop.next(true);
  }

  isDestokpView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }

  screenChange(): Observable<number> {
    return this.screenSize.asObservable().pipe(distinctUntilChanged());
  }
}
