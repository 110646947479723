import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { Faculty } from 'src/app/app.component';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-popup-faculties',
  templateUrl: './popup-faculties.component.html',
  styleUrls: ['./popup-faculties.component.scss'],
})
export class PopupFacultiesComponent implements OnInit {

  carreras: Observable<any>;
  datos: any[] = [];
  nombres: any[] = [];
  faculties: Faculty[];

  constructor(
    private popCtrl: PopoverController,
    private fsService: FirebaseService,
    private firebaseService: FirebaseService) { }

  carrerasHv = [
    { nombre: "Facultad de administración", img: "assets/img/hv1.png", Plantillas: [1, 2] },
    { nombre: "Facultad de ciencias", img: "assets/img/hv2.png", Plantillas: [1, 2] },
    { nombre: "Facultad de economía", img: "assets/img/hv3.png", Plantillas: [1] },
    { nombre: "Facultad de arquitectura y diseño", img: "assets/img/hv4.png", Plantillas: [1, 2] },
    { nombre: "Facultad de ciencias sociales", img: "assets/img/hv5.png", Plantillas: [1, 2] },
    { nombre: "Facultad de educación", img: "assets/img/h6.png", Plantillas: [1, 2] },
    { nombre: "Facultad de artes y humanidades", img: "assets/img/h7.png", Plantillas: [1, 2] },
    { nombre: "Facultad de derecho", img: "assets/img/hv8.png", Plantillas: [1, 2, 3, 4] },
    { nombre: "Facultad de educación", img: "assets/img/h9.png", Plantillas: [1, 2] },
    { nombre: "Facultad de ingeniería", img: "assets/img/h10.png", Plantillas: [1, 2] },
    { nombre: "Facultad de comunicación y lenguaje", img: "assets/img/h11.png", Plantillas: [1, 2] },
    { nombre: "Facultad de ciencias políticas y relaciones internacionales", img: "assets/img/h12.png", Plantillas: [1, 2] },
    { nombre: "Facultad de medicina", img: "assets/img/hv3.png", Plantillas: [1, 2] },
    { nombre: "Facultad de estudios naturales y rurales", img: "assets/img/hv4.png", Plantillas: [1, 2] },
    { nombre: "Facultad de ciencias jurídicas", img: "assets/img/hv5.png", Plantillas: [1, 2] },
    { nombre: "Facultad de enfermería", img: "assets/img/hv1.png", Plantillas: [1, 2] },
    { nombre: "Facultad de filosofía", img: "assets/img/hv2.png", Plantillas: [1, 2] },
  ]


  ngOnInit() {

    this.carreras = this.fsService.cargarPlantillas();
    this.carreras.subscribe(res => {
      this.datos = res[0].Carreras;
      this.datos.forEach(data => {
        this.nombres.push(data.nombre);
      })
    });

    this.firebaseService.getFaculties().subscribe((faculties: Faculty[]) => {
      this.faculties = faculties;
    });

  }

  onClick(facultadId) {
    this.popCtrl.dismiss(facultadId);
  }

}
