import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminLoginGuard } from './guards/admin-login.guard';
import { UserLoginGuard } from './guards/user-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutPageModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsPageModule)
  },
  {
    path: 'designs',
    loadChildren: () => import('./pages/designs/designs.module').then(m => m.DesignsPageModule)
  },
  {
    path: 'build-cv',
    loadChildren: () => import('./pages/build-cv/build-cv.module').then(m => m.BuildCvPageModule)
  },
  {
    path: 'admin',
    canActivate: [AdminLoginGuard],
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule)
  },
  {
    path: 'admin-net',
    canActivate: [AdminLoginGuard],
    loadChildren: () => import('./pages/admin-net/admin-net.module').then(m => m.AdminNetPageModule)
  },
  {
    path: 'purchase',
    loadChildren: () => import('./pages/purchase/purchase.module').then(m => m.PurchasePageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/perfil-usuario/perfil-usuario.module').then(m => m.PerfilUsuarioPageModule),
    canActivate: [UserLoginGuard]
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
