import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-pop-tel',
  templateUrl: './pop-tel.component.html',
  styleUrls: ['./pop-tel.component.scss'],
})

export class PopTelComponent {
  // ------------- Attributes ------------- //
  value = '';
  editing: false;

  // ------------- PopTelComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit(){
    this.popCtrl.dismiss({
        item: this.value
    });
  }

}
