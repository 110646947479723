import { CommonModule } from '@angular/common';
import { Component, Input, ViewContainerRef, ViewChild, Compiler, NgModule, OnInit, AfterViewInit } from '@angular/core';
import { CV, Template } from 'src/app/app.component';
import { transService } from 'src/app/services/translation.service';
import { PaginatedViewModule } from '../paginated-view/paginated-view.module';
// import { PaginatedViewModule } from 'src/app/components/template/paginated-view/paginated-view.module';

@Component({
  selector: 'app-template-view',
  template: `
    <template #templateContainer> </template>
  `,
})

export class TemplateViewComponent implements AfterViewInit {
  // ------------- Inputs ------------- //
  @Input() cv: CV;
  @Input() cvHtml: string;
  @Input() template: Template;
  // ------------- Elements ------------- //
  @ViewChild('templateContainer', {read: ViewContainerRef, static: false}) templateContainer: ViewContainerRef;
  // ------------- Attributes ------------- //
  constructor(private compiler: Compiler,
    private transService:transService ) {}

  ngAfterViewInit() {
    this.compileTemplate();
  }

  compileTemplate() {
    // Must clear cache.
    this.compiler.clearCache();
    // Define the component using Component decorator.
    const component = Component({
      template: this.cvHtml
    })
    (class{ cv: CV; template: Template; });
    // Define the module using NgModule decorator.
    const module = NgModule({
      imports: [
        CommonModule,
        PaginatedViewModule
      ],
      declarations: [component]
    })(class {});
    // Asynchronously (recommended) compile the module and the component.
    this.compiler.compileModuleAndAllComponentsAsync(module)
    .then(factories => {
      // Get the component factory.
      const componentFactory = factories.componentFactories[0];
      // Create the component and add to the view.
      const componentRef = this.templateContainer.createComponent(componentFactory);
      componentRef.instance.cv = this.cv;
      componentRef.instance.template = this.template;
      componentRef.instance.transService = this.transService;
      componentRef.instance.textAreaResize = this.textAreaResize;
      
    });
  }


  textAreaResize(elemen){
    const element = document.getElementById(elemen)
    element.style.height = "1px";
    element.style.height = (25+element.scrollHeight)+"px";
  }

}
