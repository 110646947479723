import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, NgZone, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScreenSizeService } from 'src/app/services/screensize.service';
import { TemplateViewComponent } from '../../template/template-view/template-view.component';

@Component({
  selector: 'app-modal-previsualization',
  templateUrl: './modal-previsualization.component.html',
  styleUrls: ['./modal-previsualization.component.scss'],
})
export class ModalPrevisualizationComponent implements AfterViewInit {
  constructor(
    private resolver: ComponentFactoryResolver,
    private screensizeService: ScreenSizeService,
    private modalCrt: ModalController) { }
  @ViewChild('container', { read: ViewContainerRef, static: false }) container: ViewContainerRef;

  cvZoom: number;
  cv
  cvHtml
  template
  end

  ngAfterViewInit(): void {
    setTimeout(x => {
      this.createTemplate();
      // console.log(window.innerWidth)
      //  this.cvZoom= window.innerWidth/2000
      this.screensizeService.screenChange().subscribe(isDesktop => {
        this.cvZoom = isDesktop / 1500;
      });
    }, 50)

  }


  createTemplate() {
    this.container.clear();
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(TemplateViewComponent);
    const componentRef = this.container.createComponent(factory);
    componentRef.instance.cv = this.cv;
    componentRef.instance.cvHtml = this.cvHtml;
    componentRef.instance.template = this.template;
    this.updateCV();
  }

  // ------------- CV ------------- //
  updateCV() {
    // Update paginated view
    this.cv.update = true;
    setTimeout(x => { this.cv.update = false; }, 500);
  }

  close() {
    this.modalCrt.dismiss();
  }

  // ------------- router ---------------//
  goToCheckout() {
    this.modalCrt.dismiss({ goToCheckout: true });
  }
}
