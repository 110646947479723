import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { Language } from 'src/app/app.component';

@Component({
  selector: 'app-pop-languages',
  templateUrl: './pop-languages.component.html',
  styleUrls: ['./pop-languages.component.scss'],
})

export class PopLanguagesComponent {

  // ------------- Attributes ------------- //
  value: Language = {
    name: '',
    level: '',
  };
  editing: false;

  // ------------- PopTelComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit(){
    this.popCtrl.dismiss({
        item: this.value
    });
  }

  close(){
    this.popCtrl.dismiss();
  }

}
