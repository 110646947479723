import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-awards',
  templateUrl: './pop-awards.component.html',
  styleUrls: ['./pop-awards.component.scss'],
})

export class PopAwardsComponent {
  // ------------- Attributes ------------- //
  value: string;
  editing: false;

  // ------------- PopAwardsComponent ------------- //
  constructor(
    private navParams: NavParams,
    private popCtrl: PopoverController) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.value = navParams.get('data');
    }
  }

  submit() {
    this.popCtrl.dismiss({
      item: this.value
    });
  }

  close() {
    this.popCtrl.dismiss();
  }
}

