import { FirebaseService } from './../../../services/firebase.service';
import { ModalTemplateComponent } from './modal-template/modal-template.component';
import { Component, ViewChild } from '@angular/core';
import { IonSelect, NavParams, ModalController } from '@ionic/angular';
import { Faculty } from 'src/app/app.component';

@Component({
  selector: 'app-modal-faculty',
  templateUrl: './modal-faculty.component.html',
  styleUrls: ['./modal-faculty.component.scss'],
})

export class ModalFacultyComponent {

  // ------------- Attributes ------------- //
  faculty: Faculty = {
    id: '',
    name: '',
    img: '',
    cv: []
  };
  imageFile: File;
  editing = false;

  // ------------- ModalFacultyComponent ------------- //
  constructor(private navParams: NavParams,
              private modalCtrl: ModalController,
              private firebaseService: FirebaseService) {
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.faculty = navParams.get('data');
    }
  }

  // ------------- Modals ------------- //
  async showModalTemplate(option: number, templateIndex: number) {
    // 3: Delete
    if (option === 3) {
      this.faculty.cv.splice(templateIndex, 1);
      this.firebaseService.removeFile(this.faculty.cv[templateIndex].html);
      this.firebaseService.removeFile(this.faculty.cv[templateIndex].img);
    }
    else {
      let modal: any;
      // 2: Edit
      if (option === 2) {
        modal = await this.modalCtrl.create({
          component: ModalTemplateComponent, mode: 'ios',
          cssClass: 'editor-experience-1', backdropDismiss: false,
          componentProps: { editing: true, data: this.copy(this.faculty.cv[templateIndex]) }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data) {
          this.faculty.cv[templateIndex] = data.item;
        }
      }
      // 1: Add
      else {
        modal = await this.modalCtrl.create({
          component: ModalTemplateComponent, mode: 'ios',
          cssClass: 'editor-experience-1', backdropDismiss: false,
          componentProps: { editing: false }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();
        if (data) {
          this.faculty.cv.push(data.item);
        }
      }
    }
  }

  submit() {
    this.modalCtrl.dismiss({
      item: this.faculty
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  // ------------- Others ------------- //
  setImage(file: any){
    (document.getElementById('imageFile') as HTMLInputElement).value = file[0].name;
    this.imageFile = file[0].name;
  }

  copy(item: any) {
    return JSON.parse(JSON.stringify(item));
  }
}
