// ------------------------------------ LIBRARIES   ------------------------------------//
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { ScreenSizeService } from 'src/app/services/screensize.service';
import { FetchService } from 'src/app/services/fetch.service';


export interface Menu {
  name: string;
  redirectTo: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})

// ------------------------------------ CLASS DEFINITION   ------------------------------------//
export class HeaderComponent implements OnInit {
  /*----- Constants -----*/
  buildUrl = '/build-hv';
  pages = ['/home', '/nosotros', '/desings', '/users-profile'];
  /*----- Attributes -----*/
  menuOptions: Menu[];
  currentPage = 'Home';
  isDesktop: boolean;
  index: number;
  user: any;

  constructor(private fetchService: FetchService,
              private modalCtrl: ModalController,
              private router: Router,
              private auth: FirebaseService,
              private auth2: AuthService,
              private screensizeService: ScreenSizeService) {
      // Screen size suscriber
      this.screensizeService.isDestokpView().subscribe(isDesktop => {
        if ((this.isDesktop && !isDesktop)) {
          window.location.reload();
        }
        this.isDesktop = isDesktop;
      });


  }

  ngOnInit() {
    this.fetchService.getMenuOptions().subscribe((menuOptions: Menu[]) => {
      try {
        this.menuOptions = menuOptions;
        this.index = this.menuOptions.findIndex(x => x.redirectTo === this.router.url);
        this.currentPage = this.menuOptions[this.index].name;
      } catch (Error){}
    });
    /*
    this.auth2.estadoActual().subscribe(res => {
      if (res.uid != null) {
        this.auth2.obtenerUsuario(res.uid).then(res => {
          this.user = res;
        })
      }else{
        this.user=undefined;
      }
    })*/

  }

  async login() {
    /*
    this.auth.userDetails().subscribe(async us => {
      if (us == undefined) {
        const modal = await this.modalCtrl.create({
          component: ModalInicioSesionComponent,
          mode: 'ios',
          backdropDismiss: true
        });
        await modal.present();
      } else {
        //USUARIO YA INICIADO
        this.router.navigate(['/perfil-usuario'])
        // console.log(us)
      }
    });*/
  }


}
