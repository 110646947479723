import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ComponentsModule } from 'src/app/components/components.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { PopupFacultiesComponent } from './popup-faculties/popup-faculties.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ComponentsModule,
        // SCrollbar
        NgScrollbarModule
    ],
    declarations: [PopupFacultiesComponent],
    exports: [PopupFacultiesComponent]
})
export class PopOverModule { }
