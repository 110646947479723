import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class transService {

  private loading: any;
  // private urlServer: string = "http://localhost:5000/grape-b7784/us-central1/"; // for testing in local server
  private urlServer = 'https://us-central1-grape-b7784.cloudfunctions.net/'; // for production

  /* ------- Page translation ------- */
  private DICTIONARY = {
    // CV words
    CONTACTO: ['Contacto', 'Contact'],
    DIRECCION: ['Dirección', 'Address'],
    TELEFONO: ['Telefono', 'Telephone'],
    CORREO: ['Correo', 'Email'],
    FECHA_DE_NACIMIENTO: ['Fecha de nacimiento', 'Date of birth'],
    HABILIDADES: ['Habilidades', 'Skills'],
    IDIOMAS: ['Idiomas', 'Languages'],
    AREAS_DE_INTERÉS: ['Área de interés', 'Area of interest'],
    PERFIL_PROFESIONAL: ['Perfil profesional', 'Professional profile'],
    EXPERIENCIA_LABORAL: ['Experiencia laboral', 'Experience'],
    ACTUAL: ['Actual', 'Current'],
    EDUCACIÓN: ['Educación', 'Education'],
    RECONOCIMIENTOS: ['Reconocimientos', 'Recognitions'],
    REFERENCIAS: ['Referencias', 'References'],
    A_SOLICITUD_DEL_INTERESADO: ['A solicitud del interesado', 'Contact'],
    // ------ cv form words ----- //
    'NOMBRE COMPLETO': ['Nombre completo', 'Full name'],
    'PROFESION': ['Profesión', 'Career'],
    'EMPECEMOS CON TU INFORMACION': ['Empecemos con tu información', 'Let\'s begin with your information'],
    'TIENES EXPERIENCIA': ['¿Tienes experiencia profesional?', 'Do you have professional experience?'],
    'FECHA NACIMIENTO': ['Fecha de nacimiento', 'Date of birth'],

    'EMPIEZA_TU_H': ['Empieza tu hoja de vida', 'Start your resume'],
    'INFO_CONTACTO': ['Información de contacto', 'Contact information'],
    'CIUDAD_RECIDENCIA': ['Ciudad de residencia', 'City of residence'],
    'CORREO_E': ['Correo electrónico', 'Email'],
    'TELEFONOS': ['Teléfonos', 'Phones'],
    'REDES_SOCIALES': ['Redes sociales / Portafolio', 'Social networks / Portfolio'],
    'INFORMA_PERFIL': ['Información de tu perfil profesional', 'Your professional profile information'],

    'VEAMOS_INFO_LABORAL': ['Ahora veamos tu experiencia laboral', "Now let's see your work experience"],
    'VEAMOS_INFO_ACADEMIC': ['Ahora veamos tu formación academica', "Now let's see your academic training"],
    'TODOS_CONOCIMIENTOS': ['Todos tus conocimientos', 'All your knowledges'],
    'SKILLS': ['Habilidades', 'Skills'],
    'TUS_LOGROS_ASPIRACIONES': ['Tus logros y aspiraciones', 'Your achievements and aspirations'],
    'LOGROS': ['Logros', 'Achievements'],
    'ASPIRACIONES': ['Intereses', 'Interests'],
    'CARGO': ['Cargo', 'Role title'],
    'EMPRESA': ['Empresa', 'Company'],
    'CIUDAD': ['Ciudad', 'City'],
    'INICIO': ['Inicio', 'Start'],
    'FIN': ['Fin', 'End'],
    'ES_EMPLEO_ACTUAL': ['¿Es tu empleo actual?', 'Current job?'],


  };

  constructor(private http: HttpClient,
              private loadingController: LoadingController ) { }

  /**
   *
   * @param text input string to translate.
   * @param lngSource string: initial language: validate with google translate API for laguanges values.
   * @param lngTarget string: desired language: validate with google translate API for laguanges values.
   * @param showLoading boolean: show loading screen while translating.
   * @returns Translated text as string.
   */
  public async translateText(text: string, lngSource: string, lngTarget: string, showLoading: boolean = true) {

    if (text === undefined || text === '' || lngSource === undefined || lngTarget === undefined) {
      console.error('Could translate as empty parameters were inserted.');
      return '';
    }

    if (showLoading) {
      await this.presentLoading();
    }

    let datos = {
      data: { text, lngSource, lngTarget }
    };
    const res = await this.http.post(this.urlServer + 'translateText', datos, {
      responseType: 'json',
      headers: {
        Accept: 'application/json',
      }
    }).toPromise();

    if (this.loading) {
      this.loading.dismiss();
    }

    return (res as any).result.translation.translatedText;

  }


  private async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'Traduciendo texto...',
      duration: 50000
    });
    await this.loading.present();
  }

  /**
   *
   * @param word word code name (spanish )
   * @param lng  lenguage to recover the word
   * @returns the word in the especified lenguage
   */
  getDicWord(word: string, lng: string) {
    switch (lng) {
      case 'es':
        return this.DICTIONARY[word][0];
      case 'en':
        return this.DICTIONARY[word][1];
    }
  }

  
}
