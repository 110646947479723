import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FetchService {

  constructor(private http: HttpClient) { }

  getCvTemplate(template: string){
    if (template !== 'undefined'){
      return this.http.get('/assets/cv/' + template + '.json').pipe(take(1));
    }
    return undefined;
  }

  getMenuOptions(){
    return this.http.get('/assets/data/menu.json').pipe(take(1));
  }

  getFacultades(){
    return this.http.get('/assets/data/facultades.json').pipe(take(1));
  }
}
