
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams, PopoverController } from '@ionic/angular';
import { PopAwardsComponent } from './pop-awards/pop-awards.component';

@Component({
  selector: 'app-modal-awards',
  templateUrl: './modal-awards.component.html',
  styleUrls: ['./modal-awards.component.scss'],
})

export class ModalAwardsComponent {
  // ------------- Attributes ------------- //
  awards: string[];

  // ------------- ModalAwardsComponent ------------- //
  constructor(private navParams: NavParams,
              private popCtrl: PopoverController,
              private modalCtrl: ModalController) {
    this.awards = navParams.get('data');
  }

  // ------------- Functions ------------- //
  async add(){
    const popover = await this.popCtrl.create({
      component: PopAwardsComponent,
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data) {
      this.awards.push(data.item);
    }
  }

  async edit(itemIndex: number){
    const popover = await this.popCtrl.create({
      component: PopAwardsComponent,
      componentProps: { editing: true, data: this.copy(this.awards[itemIndex])}
    });
    await popover.present();
    const {data} = await popover.onWillDismiss();
    if (data) {
      this.awards[itemIndex] = data.item;
    }
  }

  remove(itemIndex: number){
    this.awards.splice(itemIndex, 1);
  }

  close(){
    this.modalCtrl.dismiss();
  }

  // ------------- Others ------------- //
  copy(item: any) {
    return JSON.parse(JSON.stringify(item));
  }
}
