import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { WorkExperience } from 'src/app/app.component';
import { transService } from 'src/app/services/translation.service';
@Component({
  selector: 'app-modal-exp',
  templateUrl: './modal-exp.component.html',
  styleUrls: ['./modal-exp.component.scss'],
})

export class ModalExpComponent {
  // ------------- Attributes ------------- //
  exp: WorkExperience = {
    position: '',
    company: '',
    start: undefined,
    end: undefined,
    bossName: '',
    bossTel: '',
    responsibilities: [],
    achievements: [],
    description: '',
    city: '',
    actual: false,
  };
  editing = false;
  inEnglish = false;
  today = new Date().toJSON().split('T')[0];
  private translatedText: string;
  private rows = 20;
  saveEnglish: boolean = false;
  saveEnglish2: boolean = false;
  private language = 'es';


  // ------------- ModalExpComponent ------------- //
  constructor(private navParams: NavParams,
    private modalCtrl: ModalController,
    private transService: transService) {
    this.inEnglish = navParams.get('inEnglish');
    if (this.inEnglish) {
      this.language = 'en';
    }
    else {
      this.language = 'es';
    }
    this.editing = navParams.get('editing');
    if (this.editing) {
      this.exp = navParams.get('data');
    }
    setTimeout(x => {
      /** calculate the rows depending on each screen size */
      const divComponent = document.getElementById('content');
      const coordinatesValues = divComponent.getBoundingClientRect();
      this.rows = Math.floor(coordinatesValues.height / 30);
    }, 500);
  }

  submit() {
    this.exp.description = this.saveEnglish ? this.translatedText : this.exp.description;
    this.modalCtrl.dismiss({
      item: this.exp
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  sortArray(workExperiences: WorkExperience[]) {
    workExperiences.sort((a, b) => {
      const p = new Date(b.end);
      const s = new Date(a.end);
      return p > s ? 1 : -1;
    });
  }

  async translate() {
    this.translatedText = '' + await this.transService.translateText(this.exp.description, 'es', 'en', true);
    this.saveEnglish = true;
  }

  toggleSaveEnglish() {
    this.saveEnglish2 = !this.saveEnglish;
  }

  toggleSaveEnglis2() {
    this.saveEnglish = !this.saveEnglish2;
  }
}
