export const environment = {
  production: false,

  firebaseConfig : {
    apiKey: "AIzaSyBPuMR7SJEFvXJI-m5NRe-2Or0u102RAd4",
    authDomain: "grape-b7784.firebaseapp.com",
    databaseURL: "https://grape-b7784.firebaseio.com",
    projectId: "grape-b7784",
    storageBucket: "grape-b7784.appspot.com",
    messagingSenderId: "524400860569",
    appId: "1:524400860569:web:793102f715f479550f4708",
    measurementId: "G-9GH4LEC09P"
  }
};

