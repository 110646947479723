import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserHelperService {

  obs = new BehaviorSubject<any>({});

  constructor() { }

  setHelper(title: string, text: string) {
    this.obs.next({ text: text, visible: true, title: title });
  }

  closeHelper() {
    const tempHelper = this.obs.getValue();
    this.obs.next({ ...tempHelper, visible: false });
    console.log("clos")
  }

  getHelper(): Observable<any> {
    return this.obs;
  }
}
