import { HttpClient } from '@angular/common/http';
import { Faculty } from 'src/app/app.component';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { finalize, tap, map } from 'rxjs/operators';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
  facultiesCollection: AngularFirestoreCollection<Faculty>;
  collectionName = 'Plataforma';
  documento = 'VYg0qvTG5AMUbhyV1JMH';

  constructor(private db: AngularFirestore,
              private storage: AngularFireStorage,
              private auth: AngularFireAuth,
              private http: HttpClient) {
    this.facultiesCollection = this.db.collection('Facultades');
  }

  // -------------------- Users -------------------- //
  userDetails() {
    return this.auth.user;
  }

  getUsuario(uid: string) {
    return this.db.collection('Usuarios').doc(uid).valueChanges().pipe(map(res => {
      return res;
    }));
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  // Register in with email/password
  registrarUsuario(correo, clave, user) {
    return new Promise((resolve, reject) => {
      this.auth.createUserWithEmailAndPassword(correo, clave).then(res => {
        user.uid = res.user.uid;
        this.db.collection('Usuarios').doc(user.uid).set(user)
          .then(() => {
            resolve(true);
          }).catch(err => {
            reject(err);
          });
      }).catch(err => {
        reject(err);
      });
    });

  }

  // Sign-out
  SignOut() {
    return this.auth.signOut().then(() => {
      localStorage.removeItem('user');
      // this.router.navigate(['login']);
    });
  }

  // -------------------- Faculties -------------------- //
  addFaculty(f: Faculty) {
    return this.facultiesCollection.add(f);
  }

  updateFaculty(f: Faculty) {
    this.facultiesCollection.doc(f.id).update(f);
  }

  removeFaculty(f: Faculty) {
    this.facultiesCollection.doc(f.id).delete();
  }

  getFaculties() {
    return this.facultiesCollection.valueChanges({ idField: 'id' });
  }

  // -------------------- Files -------------------- //
  uploadFile(folder: string, file: File, filename: string) {
    return new Promise((resolve, reject) => {
      const path = folder + `/${filename}_${Date.now()}`;
      const ref = this.storage.ref(path);
      const task = this.storage.upload(path, file);
      // Wait for task completed
      task.snapshotChanges().pipe(
        finalize( () =>  {
          ref.getDownloadURL().subscribe(url => {
            resolve(url);
        });
        }),
      ).subscribe();
    });
  }

  uploadImg(folder: string, img: string, filename: string) {
    return new Promise((resolve, reject) => {
      const path = folder + `/${filename}_${Date.now()}`;
      const ref = this.storage.ref(path);
      const task = ref.putString(img, 'data_url', {contentType: 'image/jpg'});
      // Wait for task completed
      task.snapshotChanges().pipe(
        finalize( () =>  {
          ref.getDownloadURL().subscribe(url => {
            resolve(url);
        });
        }),
      ).subscribe();
    });
  }

  removeFile(url: string) {
    this.storage.refFromURL(url).delete();
  }

  getFile(url: string) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.refFromURL(url);
      ref.getDownloadURL().subscribe(data => {
        resolve(data);
      });
    });
  }

  getFileContent(url: string) {
    return new Promise((resolve, reject) => {
      const ref = this.storage.refFromURL(url);
      ref.getDownloadURL().subscribe(data => {
        fetch(data).then(response => {
          response.text().then(text => {
            resolve(text);
          });
        });
      });
    });
  }

  // -------------------- Others -------------------- //
  obtenerImg(url) {
    console.log('sirvo');
    const referencia = this.storage.ref(url);
    return referencia.getDownloadURL();
  }

  cargarPlantillas() {
    console.log('sirvo');
    return this.db.collection(this.collectionName).valueChanges({ idField: 'id' });
  }

  // Hojas de vida
  insertarHojaDeVida(HV) {
    return this.db.collection(this.collectionName).doc(this.documento).collection('HojaDeVida').add(HV);
  }

  async loginGoogle() {
    return new Promise(async (resolve, reject) => {
      this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(res => {
        console.log(res);
        const user = res.user;
        resolve({ nuevoUser: res.additionalUserInfo.isNewUser, user });
      }).catch(error => {
        reject(error);
      });
    });
  }

}
