import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-paginated-view',
  templateUrl: 'paginated-view.component.html',
  styleUrls: ['paginated-view.component.scss']
})

export class PaginatedViewComponent implements OnChanges {
  // ------------- Inputs ------------- //
  @Input() pageSize: 'A3' | 'A4' = 'A4';
  @Input() showPageNumbers = false;
  @Input() hasBanner = false;
  @Input() bannerOrientation = 'left';
  @Input() bannerColor = 'rgb(68, 68, 140)';
  @Input() fixedBannerColor = null;
  @Input() update = true;
  @Input() firstColumnSize = "67%";
  @Input() secondColumnSize = "33%";
  @Input() hasMiddleLine = false;
  @Input() middleLineColor = "black";
  @Input() background = "white";
  // ------------- Elements ------------- //
  @ViewChild('paginatedView') paginatedView: ElementRef<HTMLDivElement>;
  @ContentChildren('pageContent', { read: ElementRef }) elements: QueryList<ElementRef>;
  // ------------- Attributes ------------- //
  pages: HTMLDivElement[] = [];

  pageLeftColum
  pageRightColum

  // ------------- PaginatedViewComponent ------------- //
  constructor() { }

  ngOnChanges() {
    setTimeout(x => {
      if (this.update) {
        this.updatePages();
      }
    }, 200);
  }

  updatePages(): void {
    // clear paginated view
    this.paginatedView.nativeElement.innerHTML = '';
    this.pages = [];
    // get a new page and add it to the paginated view
    let page = this.getNewPage();
    this.paginatedView.nativeElement.appendChild(page);

    this.pageRightColum = this.pageLeftColum = 0;

    let lastEl: HTMLElement;
    // add content childrens to the page one by one
    this.elements.forEach(elRef => {
      const el = elRef.nativeElement;
      // if the content child height is larger than the size of the page
      // then do not add it to the page
      if (el.clientHeight > page.clientHeight) {
        return;
      }
      // If lateral banner
      if (this.hasBanner) {

        if (el.className === 'leftColumn') {
          this.pages[this.pageLeftColum].children[0].children[0].appendChild(el);
        }
        else if (el.className === 'rightColumn') {
          //   console.log(this.pageRightColum)
          this.pages[this.pageRightColum].children[0].children[1].appendChild(el);
        }
      }
      else {
        page.children[0].appendChild(el);
      }

      // after adding the child if the page scroll hight becomes larger than the page height
      // then get a new page and append the child to the  new page

      if ((this.pages[this.pageLeftColum].scrollHeight > this.pages[this.pageLeftColum].clientHeight) ||
        (this.pages[this.pageRightColum].scrollHeight > this.pages[this.pageRightColum].clientHeight) ||
        (page.scrollHeight > page.clientHeight)) {

        //  el.classList.add("page-margin-top")
        // If lateral banner
        if (this.hasBanner) {          //   3   4
          if ((el.className === 'leftColumn' && this.pageLeftColum === this.pages.length - 1)
            || (el.className === 'rightColumn' && this.pageRightColum === this.pages.length - 1)) {
            page = this.getNewPage();
            this.paginatedView.nativeElement.appendChild(page);
          }

          if (el.className === 'leftColumn') {
            this.pageLeftColum++;
            // console.log(this.pageLeftColum)
            this.pages[this.pageLeftColum].children[0].children[0].appendChild(el);

          }
          else if (el.className === 'rightColumn') {
            this.pageRightColum++;
            //  console.log(this.pageRightColum)
            this.pages[this.pageRightColum].children[0].children[1].appendChild(el);
          }
        }
        else {
          page = this.getNewPage();
          this.paginatedView.nativeElement.appendChild(page);
          page.children[0].appendChild(el);
        }
      }
      lastEl = el;
    });
    // if (this.showPageNumbers) {
    //   this.updatePageNumbers();
    // }
  }

  getNewPage(): HTMLDivElement {
    const page = document.createElement('div');
    const pageContent = document.createElement('div');
    const bannerVertical = document.createElement('div');

    // Page
    page.classList.add('page');
    page.style.background = this.background;
    if (this.pages.length !== 0) {
      // This adds page margin to the pages except the fisrt one
      page.classList.add(this.pageSize);
      pageContent.classList.add('page-content');
      // page.style.padding = "padding: 10px 0px 30px 0px;"
    } else {
      // This adds no top margin to the first page
      page.classList.add('A4-1');
      pageContent.classList.add('page-content-1');
    }

    // Banner
    if (this.hasBanner) {
      const leftContent = document.createElement('div');
      const rightContent = document.createElement('div');
      leftContent.style.float = 'left';
      rightContent.style.float = 'left';
      if (this.hasMiddleLine)
        rightContent.style.borderLeft = "1px solid " + this.middleLineColor;


      console.log("rendering: ", this.bannerColor, this.fixedBannerColor)
      if (this.bannerOrientation === 'left') {
        leftContent.style.height = '100%';
        leftContent.style.width = this.secondColumnSize;
        rightContent.style.width = this.firstColumnSize;
        leftContent.style.background = this.fixedBannerColor === null ? this.bannerColor : this.fixedBannerColor;
      }
      else {
        rightContent.style.height = '100%';
        leftContent.style.width = this.firstColumnSize;
        rightContent.style.width = this.secondColumnSize;
        rightContent.style.background = this.fixedBannerColor === null ? this.bannerColor : this.fixedBannerColor;;
      }
      pageContent.appendChild(leftContent);
      pageContent.appendChild(rightContent);
    }


    page.appendChild(pageContent);
    this.pages.push(page);
    return page;
  }

  updatePageNumbers(): void {
    const totalPages = this.pages.length;
    this.pages.forEach((page, index) => {
      const pageNumber = document.createElement('span');
      pageNumber.classList.add('page-number');
      pageNumber.innerText = `Page ${index + 1} of ${totalPages}`;
      page.appendChild(pageNumber);
    });
  }
}
