import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { rejects } from 'assert';
import firebase from 'firebase';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private auth: AngularFireAuth,
    private db: AngularFirestore) { }


  estadoActual() {
    return this.auth.authState;
  }



  obtenerUsuario(uid) {
    return new Promise((exito, fail) => {
      this.db.collection('Usuarios').doc(uid).get().subscribe(res => {
        if (res !== null) {
          exito(res.data());
        }
        else {
          fail(res);
        }
      });

    });

  }


  //Usuario
  userDetails() {
    return this.auth.user;
  }

  getUsuario(uid: string) {
    return this.db.collection('Usuarios').doc(uid).valueChanges().pipe(map(res => {
      return res;
    }));
  }

 async  getUserData(uid: string) {
    return (await this.db.collection('Usuarios').doc(uid).get().toPromise()).data();
  }

  // Login in with email/password
  SignIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
  // Register in with email/password
  registrarUsuario(correo, clave, user) {
    return new Promise((resolve, reject) => {
      this.auth.createUserWithEmailAndPassword(correo, clave).then(res => {
        user.uid = res.user.uid;
        this.db.collection("Usuarios").doc(user.uid).set(user)
          .then(res => {
            resolve(true)
          }).catch(err => {
            reject(err)
          })
      }).catch(err => {
        reject(err)
      })
    });

  }

  SignOut() {
    this.auth.signOut();
  }

  async loginGoogle() {
    return new Promise(async (resolve, reject) => {
      this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(res => {
        console.log(res);
        const user = res.user;
        resolve({ nuevoUser: res.additionalUserInfo.isNewUser, user: user })
      }).catch(error => {
        reject(error)
      })
    })
  }

  getUserStatus() {
    return this.auth.currentUser;
  }
}
